import { httpClient } from "@/console/app/client/httpClient";
import { Result } from "@/console/types/verification_request_queue/Result";

class AccountService {
  fetchUserData() {
    return httpClient.accounts.me.$get();
  }

  fetchVerificationRequestQueue(id: number) {
    return httpClient.accounts.admin.verification_request_queue._id(id).$get();
  }

  fetchVerificationRequestQueues({
    /* eslint-disable @typescript-eslint/naming-convention */
    limit,
    offset,
    result__in,
    result__isnull,
    requestedAtFrom,
    requestedAtTo,
    processedDate,
    processedDateIsNull,
    userId,
    workerId,
    workerName,
    ordering
  }: {
    limit?: number;
    offset?: number;
    result__in?: string | null;
    result__isnull?: boolean | null;
    requestedAtFrom?: string | null;
    requestedAtTo?: string | null;
    processedDate?: string | null;
    processedDateIsNull?: boolean;
    userId?: string | null;
    workerId?: string;
    workerName?: string | null;
    ordering?: string;
  }) {
    let query = { limit, offset, result__in, result__isnull, ordering };
    if (requestedAtFrom) {
      query = Object.assign(query, { requested_at__gte: requestedAtFrom });
    }
    if (requestedAtTo) {
      query = Object.assign(query, { requested_at__lte: requestedAtTo });
    }
    if (processedDate) {
      query = Object.assign(query, { processed_date__gte: processedDate });
    }
    if (processedDateIsNull !== undefined) {
      query = Object.assign(query, {
        processed_date__isnull: processedDateIsNull
      });
    }

    if (userId) {
      query = Object.assign(query, {
        user__username__icontains: userId
      });
    }
    if (workerId) {
      query = Object.assign(query, {
        user_id: workerId
      });
    }
    if (workerName) {
      query = Object.assign(query, {
        user__full_name__icontains: workerName
      });
    }
    /* eslint-enable */

    return httpClient.accounts.admin.verification_request_queue.$get({ query });
  }

  fetchRejectionReasons(limit?: number, offset?: number) {
    return httpClient.accounts.rejection_reason.$get({
      query: { limit, offset }
    });
  }

  updateVerificationRequestQueue({
    id,
    result,
    comment,
    rejectionReason,
    shouldDeleteIdImageFile = false,
    dontNotifyWorker = false,
    avatar,
    updated_at
  }: {
    id: number;
    result: Result;
    comment?: string | null;
    rejectionReason?: number | null;
    shouldDeleteIdImageFile?: boolean;
    dontNotifyWorker?: boolean;
    avatar?: string | null;
    updated_at: string;
  }) {
    return httpClient.accounts.admin.verification_request_queue._id(id).$patch({
      body: {
        result,
        comment,
        rejectionReason,
        shouldDeleteIdImageFile,
        dontNotifyWorker,
        avatar,
        updated_at
      }
    });
  }

  checkDuplicate(id: number) {
    return httpClient.accounts.admin.verification_request_queue
      ._id(id)
      .check_duplicate.$post();
  }
}

export const accountService = new AccountService();
