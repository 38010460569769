import { RouteLocation } from "vue-router";

const COMMIT_VERSION_LENGTH = 8;

async function fetchVersionFromRemote() {
  const res = await fetch(`/latest-version?${new Date().getTime()}`);
  const value = await res.text();
  return value?.length === COMMIT_VERSION_LENGTH ? value : null;
}
export function fetchVersionFromDocument() {
  const el = document.querySelector('meta[name="commit"]');
  const value = el?.getAttribute("value");
  return value?.length === COMMIT_VERSION_LENGTH ? value : null;
}

export async function updateIfNewerVersionExists() {
  const now = new Date().getTime();
  const lastUpdateCheckTime = localStorage.getItem("lastUpdateCheckTime");
  // 万が一index.htmlがキャッシュされてしまった場合に無限ループになるのを防ぐため
  // 3分以内に更新チェックを行っていたらスキップする
  if (
    lastUpdateCheckTime &&
    now - parseInt(lastUpdateCheckTime, 10) < 3 * 60 * 1000
  ) {
    return;
  }
  localStorage.setItem("lastUpdateCheckTime", now.toString());
  const version = fetchVersionFromDocument();
  try {
    const latestVersion = await fetchVersionFromRemote();
    if (latestVersion && version) {
      if (latestVersion !== version) {
        location.reload();
        return;
      } else {
        // すでに最新バージョンになっている場合は何もしない
      }
    } else {
      console.log("バージョン情報が取得できませんでした");
    }
  } catch {
    // 通信できない場合には何もしない
    return;
  }
}

export const autoUpdateHook = (to: RouteLocation) => {
  // 下記のpathに遷移したときautoUpdateのチェックを行う
  if (
    ![
      "/tasks", // タスク一覧
      "/works", // 案件一覧
      "/templates", // テンプレート一覧
      "/workers", // 応募ワーカー一覧
      "/messages", // メッセージ
      "/offices", // 事業所一覧
      "/console/verifyqueues", // アカウント認証
      "/console/qualifications", // 資格認証
      "/console/insufficientattendances", // 未出退勤
      "/console/cancelrequests", // キャンセル
      "/console/attendancecorrections", // 勤怠変更申請
      "/console/giftrequests", // ポイント交換
      "/console/transferrequests/withdrawal", // 早期引出
      "/console/transferrequests/salary", // 定期振込
      "/console/corporations/accounts/requests", // 法人アカウント発行
      "/console/workers", // ワーカー
      "/console/offices", // 事業所
      "/console/works", // 案件
      "/console/templates", // テンプレート
      "/console/adminreports", // レポート
      "/console/billing/payment" // 法人入金
    ].includes(to.path)
  ) {
    return;
  }
  updateIfNewerVersionExists().then();
};
